@import 'src/variables';

.logoutMenuItem {
  align-items: center;
  margin-top: auto;
  @include tablet {
    margin-top: 16px;
  }
}

.logoutMenuItem__link {
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 16px;
  display: flex;
  @include tablet {
    padding: 2px 0;
  }
  &:hover {
    background-color: rgba(1, 1, 1, .05);
  }
}

.logoutMenuItem__text {
  color: $darkGrey;
  padding: 0 16px;
  @include tablet {
    margin: 0;
    padding: 8px 0;
    font-size: 18px;
    font-weight: normal;
  }
}

.logoutMenuItem__avatar {
  @include tablet {
    display: none !important;
  }
}
