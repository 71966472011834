@import 'src/variables';

.wallchart__header--static {
  height: unset;
  @include desktopSmall {
    padding-bottom: 12px;
  }
}

.wallchartRow__editButton {
  display: none !important;
  @include smallTablet {
    max-width: 180px;
    display: flex !important;
  }
  &--mobile {
    display: flex !important;
    align-self: center;
    width: 100%;
    margin: 8px 0 16px 0;
    @include smallTablet {
      display: none !important;
    }
  }
}

.wallchartRow__acceptBtn {
  margin: 0 auto 16px auto;
  @include smallTablet {
    margin: 0;
  }
}

.wallchartRow__nameIcon {
  transition: transform .25s;
  transform: rotate(0deg);
  &--open {
    transform: rotate(-90deg);
  }
}
//
//.wallchartRow__movements {
//  flex: 1;
//  height: 100%;
//  width: 100%;
//  display: flex;
//  align-items: center;
//  min-width: 350px;
//}
//
//.wallchartRow__day {
//  flex: 1;
//  height: 100%;
//  min-height: 72px;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//}
//
//.wallchartRow__day--activeEdit {
//  .movement__icon {
//    border-color: $blue;
//  }
//}
