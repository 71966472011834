@import 'src/variables';

.userRow__desksAvailable {
}

.userRow__desks__number {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: 32px;
  color: #1AA85C;
  border: 1px solid #1AA85C;
  border-radius: 100%;
}

.userRow__desks__number--low {
  @extend .userRow__desks__number;
  color: $black;
  border-color: $black;
}
