
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.headline__xlarge {
  letter-spacing: -1px;
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
  line-height: 50px;
  @include tablet {
    font-size: 62px;
    line-height: 64px;
  }
}

.headline__large {
  letter-spacing: -1px;
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 38px;
  @include tablet {
    font-size: 44px;
    line-height: 46px;
  }
}


.headline__verysmall {
  color: $black;
  font-family: "soleil", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.headline__xsmall {
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
