@import "src/variables";

.onboarding {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include tablet {
    max-width: 480px;
  }
}

.onboarding__loading {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 16px auto 0 auto;;
}

.onboarding__image {
  width: 300px;
  align-self: center;
  &--fullWidth {
    width: 100%;
  }
}

.onboarding__teamMemberControls {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  .teamMemberControls__next {
    width: 100%;
    margin-bottom: 16px;
  }
  .teamMemberControls__back {
    width: 50%;
    text-align: left;
    cursor: pointer;
  }
  .teamMemberControls__later {
    justify-self: flex-end;
  }
}

.onboardingComplete__controls {
  margin-bottom: 32px;
}

.onboarding__input {
  margin-bottom: 48px;
  width: 100%;
}

.onboarding__multiTeams {
  margin: 0 auto;
  font-size: 16px;
}

.onboarding__content {
  width: 100%;
  max-width: 100%;
  @include tablet {
    max-width: 480px;
  }
}

.onboarding__title {
  margin-bottom: 16px;
}

.onboarding__button {
  width: 100%;
  margin-bottom: 26px;
  min-height: 50px;
  font-size: 18px;
}

.onboarding__description {
  margin-bottom: 24px;
}

.onboarding__controlRow {
  justify-content: flex-end;
  width: 100%;
  .linkButton {
    width: unset;
  }
}

.onboarding__complete {
  margin-bottom: 12px;
}

.onboarding__icon {
  width: 48px;
  height: 48px;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 24px;
}


.onboardingFeature {
  margin-bottom: 16px;
  padding: 16px 12px 16px 24px;
  border-radius: 12px;
  border: 2px solid $mildGrey;
  transition: border .25s ease-in-out;
  cursor: pointer;
  position: relative;
}

.onboardingFeature__iconContainer--selected {
  background: #C4EC17 !important;
}

.onboardingFeature--selected {
  border-color: $blue;
  border-width: 2px;
  img {
    box-shadow: 0px 4px 6px 2px #9EC106;
  }
}

.onboardingFeature__iconContainer {
  margin-right: 24px;
  padding: 8px;
  background: $lightGrey;
  border-radius: 8px;
  transition: background .25s ease-in-out;
  img {
    transition: box-shadow .25s ease-in-out;
    border-radius: 12px;
    background-color: white;
    padding: 12px;
  }
}
