/* Customize the label (the container) */
.checkbox__container {
  display: inline-flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox--flip {
  padding-left: 0;
  .checkbox__checkmark {
    right: 0;
    left: unset;
  }
}

.checkbox__container--disabled {
  border-color: $mildGrey !important;

  & > * {
    border-color: $mildGrey !important;
  }

  & .checkbox__checkmark:after {
    border-color: $mildGrey !important;
  }

  & .checkbox__checkmark {
    background-color: white;
  }

  p {
    color: $darkGrey;
  }
}

/* Hide the browser's default checkbox */
.checkbox__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid $darkGrey;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox__container:hover input ~ .checkbox__checkmark {
  background-color: white;
  border: 1px solid $blue;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.checkbox__container input:checked ~ .checkbox__checkmark {
  background-color: $white;
  border-color: $blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox__container input:checked ~ .checkbox__checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox__container .checkbox__checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $blue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
