@import 'src/variables';

.footer {
  width: 100%;
  background-color: $lightGrey;
  min-height: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
