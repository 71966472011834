@import 'src/variables';

.iconButton__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  color: $darkGrey;
  cursor: pointer;
  transition: background-color .25s;
  border-radius: 2px;
}

.iconButton--mediumlarge {
  padding: 8px 0;
}

.iconButton--fullWidth {
  width: 100%;
}

.iconButton--darkGrey {
  color: $darkGrey;
  p {
    color: $darkGrey;
  }
}

.iconButton--disabled {
  opacity: 0.5;
  color: $mildGrey;
  p {
    color: $mildGrey;
  }
}

.iconButton__link {
  text-decoration: none;
}
