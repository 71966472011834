@import 'src/variables';

.notification {
  width: 90%;
  max-width: 400px;
  position: fixed;
  bottom: 0;
  right: 5%;
  height: 0;
  max-height: 92px;
  background-color: $black;
  border-radius: 12px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 999999;
  img {
    margin-left: 16px;
  }
}

.notification--open {
  bottom: 16px;
  height: 64px;
}

.notification__message {
  line-height: 20px;
}

.notification__messageContainer {
  padding: 18px 16px 18px 16px;
}

.notification__icon {
  margin: 0 0 0 16px;
  font-size: 22px;
}

.notification--success {
  background-color: $veryDarkBlue;
  border: 0px;
  .notification__message {
    color: $white !important;
  }
}

.notification--warning {
  background-color: #FFF9F4;
  border: 1px solid $orange !important;
}

.notification--error {
  background-color: #FEF4F6;
  border: 1px solid $red !important;
}

.notification--green {
  background-color: $veryDarkBlue;
  color: white;

  .notification__icon {
    background-color:$green;
    border-color:$green;
    color: white;
  }
}

