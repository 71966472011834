.selectableContentCard {
  background: #FFFFFF;
  border: 0.5px solid $mildGrey;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
  ul {
    list-style-position: inside;
  }
  p {
    display: inline;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.selectableContentCard__titleRow {
  display: flex;
  justify-content: space-between;
}

.selectableContentCard--selected {
  border-color: $blue;
  box-shadow: 0px 0px 17px 2px rgba(0, 87, 255, 0.15);
  .selectableContentCard__title {
    color: $blue;
  }
}

.selectableContentCard--disabled {
  cursor: default;
}