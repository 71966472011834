@import 'src/variables';

.verifyRequied__icon {
  width: 48px;
  margin-bottom: 36px;
  @include tablet {
    margin-bottom: 24px;
    align-self: flex-start;
  }
}

.verifyRequired__header {
  margin-bottom: 16px;
  align-self: flex-start;
}

.verifyRequired__description {
  margin-bottom: 24px !important;
  line-height: 24px;
}

.verifyRequired__info {
  margin-bottom: 0 !important;
  line-height: 24px;
}

.verifyRequired__button {
  align-self: flex-start;
  padding: 0;
  span {
    font-size: 18px;
  }
}
