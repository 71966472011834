
$diameter: 32px;

.userAvatarAndName__avatar {
  color: white;
  font-size: 70%;
  border-radius: 50%;
  height: $diameter;
  width: $diameter;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: $diameter;
  min-height: $diameter;
}

.userAvatarAndName__editLink {
  width: 0;
  transition: width ease-in-out .25s, background-color ease-in-out .25s, opacity ease-in-out .25s;
  overflow: hidden;
  margin-left: 8px;
  opacity: 0;
}

.userAvatarAndName {
  display: inline-flex;
  align-items: flex-start;
  @include smallTablet {
    max-width: 100%;
    align-items: center;
  }
}

.userAvatarAndName__name {
  margin-top: -6px;
}

.userAvatarAndName__name--jobTitle {
  margin-top: -6px !important;
}

.userAvatarAndName__jobTitle {
  margin-top: -4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userAvatarAndName__editLink--hover {
  cursor: pointer;
  padding-right: 8px;
  &:hover {
    .userAvatarAndName__editLink {
      width: 24px;
      opacity: 1;
    }
    background-color: $lightGrey;
    border-radius: 16px;
  }
}

.userAvatarAndName__avatar--blue {
  background-color: $blue;
}

.userAvatarAndName__avatar--darkBlue {
  background-color: $darkBlue;
}

.userAvatarAndName__avatar--transparent {
  background-color: transparent;
  border: 1px solid $darkGrey;
  border-radius: 100%;
  color: $darkGrey !important;
}

.userAvatarAndName__avatar--visitor {
  position: relative;
  background-color: $green;
  color: $white;
  border-radius: 100%;
  border: 3px solid $green;
}

.userAvatarAndName__avatar--visitor:before {
  position: absolute;
  display: block;
  content: '';
  border: 1px solid $white;
  height: 100%;
  width: 100%;
  color: $white;
  border-radius: 100%;
}

.userAvatarAndName__avatar--lightGrey {
  background-color: $lightGrey;
}

.userAvatarAndName__avatar--green {
  background-color: $green;
}

.userAvatarAndName__avatar--none, .userAvatarAndName__avatar--grey {
  background-color: $darkGrey;
}

.userAvatarAndName--darkBlue {
  color: $white;
}

.userAvatarAndName--blue {
  color: $blue;
}

.userAvatarAndName--green {
  color: $green;
}

.userAvatarAndName--none {
  color: $darkGrey;
}

.userAvatarAndName--visitor {
  color: $green;
}

.userAvatarAndName__access {
  margin-left: auto;
}
