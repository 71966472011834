@import 'src/variables';

.userList__row {
  margin: 2px -4px;
  padding: 8px 4px;
  transition: background-color .1s;
  border-radius: 2px;
  &:hover {
    background-color: $blue10;
  }
}
