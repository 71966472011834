
$header-height: 50px;

.mobileEditMovements__container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  z-index: 999;
  top: 0;
  left: 0;
  @include smallTablet {
    display: none;
    height: 0;
    width: 0;
  }
}

.mobileEditMovements__header {
  width: 100%;
  background-color: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  transition: height .25s;
  overflow: hidden;
  height: 0;
  position: absolute;
  z-index: 9;
}

.mobileEditMovements__container--open {
  .mobileEditMovements__header {
    height: $header-height;
  }

  .mobileEditMovements__body {
    top: 0;
    height: 100vh;
  }
}

.mobileEditMovements__headerWeekFlicker {
  width: 90%;
  min-width: 300px;
}

.mobileEditMovements__body {
  width: 100vw;
  height: 0;
  background-color: $white;
  transition: height .5s, top .5s;
  bottom: 0;
  top: 100%;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.25);
  padding-top: $header-height;
}

.mobileEditMovements__controls {
  padding: 30px 16px 70px 16px;
  .button {
    width: 100%;
  }
}

.mobileEditMovements__controls--hidden {
  display: none;
}