
.companyPage__highlight {
  color: $green;
}

.companyMovements__header {
  margin-bottom: 8px;
}

.companyMovements__sidebar {
  justify-content: flex-start;
  display: none !important;
  padding-top: 16px;
  @include tablet {
    padding-left: 16px;
    padding-top: 32px;
  }
  @include desktopSmall {
    display: flex !important;
    min-width: 23%;
    .calendar {
      align-self: center;
    }
  }
}

.companyMovements__sidebar img {
  width: 200px;
  margin-top: 24px;
  align-self: center;
  margin-bottom: 24px;
}

.companyMovements__movements {
  justify-content: flex-start;
  width: 100%;
  padding-right: 0;
  padding-top: 16px;
  @include tablet {
    padding-top: 32px;
  }
  @include desktopSmall {
    //max-width: 650px;
    min-width: 75%;
    padding-right: 2%;
  }
}

.teamDropdown__header {
  min-height: 32px;
  border-left: solid 3px $green;
  display: flex;
  cursor: pointer;
  z-index: 15;
  position: relative;
  align-items: center;
  h3 {
    padding: 4px 12px;
  }
}

.companyMovements__controls {
  display: flex;
  flex-direction: column;
  @include smallTablet {
    flex-direction: row;
    justify-content: space-between;
  }
}

.teamDropdown__headerControls {
  display: flex;
  align-items: center;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .25s;
    transform-origin: 50% 50%;
    font-size: 28px;
  }
}

.teamDropdown__teamName {
  margin-right: 16px;
}

.teamDropdown__container--expanded {
  .teamDropdown__header i {
    transform: rotate(90deg);
  }
  .teamDropdown__content {
    max-height: 0;
  }
}

.teamDropdown__movement .weeklyMovements__row--teamMember {
  margin: 0;
  padding: 0;
}

.teamDropdown__container .weeklyMovementsViewer__cell {
  border: none;
}

.noTeamMembers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background-color: $veryLightGrey;
}

.noTeamMembers__content {
  max-width: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $opaqueMildGrey;
  border-radius: 12px;
  p {
    margin-bottom: 12px;
  }
}

.companyMovements__addTeam {
  display: none;
  max-height: 42px;
  @include smallTablet {
    display: block;
  }
}

.columnSpacer__list {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 26px;
  @include smallTablet {
    margin-left: 35%;
    padding: 0px 14px 0 14px
  }
  @include tablet {
    margin-bottom: 0;
    box-shadow: none;
    background-color: unset;
  }
}

.columnSpacer {
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @include smallTablet {
    flex: 1;
    height: 32px;
  }
  @include tablet {
    align-items: flex-end;
  }
}
