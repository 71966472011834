
.activityList {
  width: 100%;
}

.activityList__title {
  margin-bottom: 24px;
  display: none;
  @include smallTablet {
    display: block;
  }
}

.activity {
  background-color: $white;
  border: 0.5px solid $mildGrey;
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 0 16px 0;
  cursor: pointer;
  transition: box-shadow .2s;
  user-select: none;
  &:hover {
    box-shadow: 6px 6px 6px -4px rgba(0,0,0,0.2);
    background-color: white;
    border-color: $blue;
    p {
      color: $black;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.activity--disabled {
  background-color: $veryLightGrey;
  img {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  p {
    color: $darkGrey !important;
  }
  &:hover {
    background-color: $veryLightGrey;
    box-shadow: none;
    border-color: $mildGrey;
    p {
      color: $darkGrey !important;
    }
    .activity__editOfficeButton {
      color: $blue !important;
      border-color: $blue !important;
    }
  }
  .activity__editOfficeButton {
    color: $blue !important;
    border-color: $blue !important;
  }
}

.activity p {
  color: $darkGrey;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  @include smallTablet {
    white-space: unset;
    overflow: unset;
    max-width: unset;
  }
}
