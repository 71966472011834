
.auth__page {
  padding-bottom: 80px;
}

.auth__headerImage {
  margin: 0 auto;
}

.auth__container {
  max-width: 524px;
  padding: 48px 24px;
  background-color: white;
  box-shadow: 12px 12px 32px $blue20;
  margin: 0 8px 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 524px) {
    margin: 32px auto 0 auto;
  }
  @include tablet {
    padding: 64px;
  }
}

.auth__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.auth__icon {
  align-self: center;
  margin-bottom: 32px;
  @include tablet {
    margin-bottom: 24px;
    align-self: flex-start;
  }
}

.auth__backLink {
  margin: 32px 0 0 0;
  width: 100%;
  text-align: center;
  a {
    text-align: center;
    text-decoration: none;
    color: $blue;
  }
}

.auth__title {
  align-self: flex-start;
  margin-bottom: 16px;
  @include tablet {
    margin-bottom: 24px;
  }
}

.auth__link {
  color: $blue;
}


.login__formControls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.login__shortDivider {
  width: 68px;
  height: 1px;
  background-color: $mildGrey;
}

.login__error {
  margin-bottom: 16px;
  color: $red;
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
}

.login__button {
  height: 52px;
  margin-bottom: 16px;
  @include tablet {
    align-self: center;
    width: 90%;
  }
}

.login__button--teamToday {
  img {
    max-width: 24px;
  }
}

.login__checkbox {
  a {
    color: $blue;
    text-decoration: none;
    font-weight: 600;
  }
}

.login__alternative {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  a {
    margin-left: 4px;
    text-decoration: none;
    color: $blue;
  }
}
