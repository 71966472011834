
.weekCommencingDate {
  color: $blue;
  align-items: center;
}

.weekCommencingDate__icon {
  margin-right: 8px
}

.weekCommencingDate span {
  margin-right: 4px;
}

.calendarIcon {
  background-image: url('https://app.team-today.com/assets/icons/calendar.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
