@import 'src/variables';

.periodList {
  display: flex;
  margin: 0 0 24px 0;
  user-select: none;
  @include tablet {
    flex-direction: column;
  }
}

.periodList--horizontal {
  flex-direction: row !important;
  .periodList__period {
    margin-right: 16px !important;
  }
  .periodList__period:last-child {
    margin-right: 0 !important;
  }
}

.periodList__period {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 0.5px solid $mildGrey;
  box-sizing: border-box;
  border-radius: 8px;
  flex: 1 1 0;
  margin: 0 8px 12px 8px;
  background-color: $veryLightGrey;
  cursor: pointer;
  p {
    color: $darkGrey;
  }
  &:first-child, &:last-child {
    margin: 0 0 12px 0;
  }
  @include tablet {
    margin: 0 0 12px 0;
  }
}

.periodList__period--selected {
  background-color: $white;
  border-color: $blue;
  p {
    color: $blue;
  }
}
