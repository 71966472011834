@import 'src/variables';

.setupCompanyDialog__title {
  margin-bottom: 16px;
}

.setupCompanyDialog {
  max-width: 396px;
  margin: 0 auto;
}
