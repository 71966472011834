@import 'src/variables';

.editMovements__content {
  padding: 24px;
}

.editMovements__content.column {
  padding: 24px;
}

.editMovements__periods {
  justify-content: flex-start;
  max-width: 220px;
  margin-right: 17%;
}

.editMovements__saveBtn {
  line-height: 16px;
  margin: 16px 0;
}

.editMovements__clearBtn {
  line-height: 16px;
  border-color: $darkGrey;
  color: $darkGrey;
}

.editMovements__activityList {
  align-self: flex-start;
  width: 68%;
  max-width: 504px;
  .activityList {
    width: 90%;
  }
}

.editMovements__carParking {
  margin-bottom: 16px;
}
