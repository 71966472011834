

.forgotPassword__content p {
  align-self: flex-start;
  margin-bottom: 12px;
}

.resetPassword__button {
  padding: 12px 0;
  margin: 20px 0 0 0;
}

.forgotPassword__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.login__accountExtras {
  margin-top: auto;
}
