

$header-height: 58px;

.subnav {
  padding: 0 16px;
}

.subnav--desktop {
  display: none;
  @include tablet {
    display: flex;
    align-self: flex-end;
  }
}

.subnav__item {
  width: 50%;
  text-align: center;
  user-select: none;
  height: $header-height;
  display: flex;
  align-items: center;

  * {
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  p {
    color: $white;
    padding: 12px 0;
    line-height: 18px;
  }

  @include tablet {
    padding: 0 24px;
    width: unset;
  }
  @include desktop {
    margin: 0 8px;
    p {
      line-height: 18px;
    }
  }
}

.subnav__item--selected {
  background-color: $blue;
  p {
    color: $white !important;
  }
}
