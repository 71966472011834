@import 'src/variables';

.wallchart {
  display: flex;
  flex-direction: column;
}

.wallchart__team {
  position: relative;
  width: 100%;
  margin-bottom: 48px;
  @include desktopSmall {
    min-width: 640px;
  }
  & > * {
    border-bottom: 1px solid $mildGrey;
    &:last-child {
      border-bottom: none;
    }
  }
}

.wallchart__teams {
  margin-bottom: 32px;
  .wallchart__team {
    margin-top: 32px !important;
  }
}

.wallchart__addTeams {
  @include desktopSmall {
    align-self: center;
  }
}

.wallchart__addTeams--mobile {
  @include tablet {
    display: none;
  }
}
.wallchart__addTeams--desktop {
  min-height: 36px !important;
}
