
.addTeamDialog__switch {
  width: 100%;
  justify-content: space-between;
}

.addTeamDialog__addSelf {
  width: 100%;
  margin: 24px 0 48px 0;
}
