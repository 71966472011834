@import 'src/variables';

.teamDropDownList {
  border: 1px solid RGBA(8, 30, 34, 0.5);
  padding: 8px 16px;
  border-radius: 1px;
  width: 100%;

  font-family: "proxima-nova";
  font-size: 18px;
  line-height: 28px;

  margin-bottom: 24px;
  cursor: pointer;
}

.teamDropDownList--fullWidth {
  width: 100%;
}

.teamDropDownList__label {
  margin-bottom: 8px;
}
