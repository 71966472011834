
.weeklyMovementsViewer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.weeklyMovementsViewer .button {
  align-self: flex-end;
}

.weeklyMovementsViewer__user {
  i {
    margin-right: 16px;
  }
}

.weeklyMovementsViewer__cell {
  flex: 1;
  margin: 8px 6px 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @include tablet {
    margin: 0 0;
    z-index: 999;
    border-bottom: 1px solid $mildGrey;
  }
}

.weeklyMovementsViewer__cell--disabled {
  opacity: .5;
}

.weeklyMovementsViewer__cell--active {
}

.weeklyMovementsViewer__header {
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
  justify-content: flex-end;
  text-align: right;
}

.weeklyMovementsViewer__row {
  display: flex;
  padding: 12px 0;
}

.weeklyMovements__avatar {
  width: 100%;
  .button {
    display: none;
    align-self: flex-start;
  }
  @include smallTablet {
    display: inline-flex;
    width: 32%;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .button {
      display: flex;
      margin: 8px 0 0 0;
      min-width: 192px;
      height: 36px;
    }
  }
}

.weeklyMovementsViewer__profile {
  display: flex;
  flex: 1;
  align-items: center;
  i {
    margin-right: 16px;
  }
}

.weeklyMovementsViewer__movements {
  display: flex;
}

.weeklyMovementsViewer__movement {
  display: inline-flex;
  font-size: 32px;
  margin: 0 24px;
}

.weeklyMovementsViewer__movementHeader {
  margin: 0 24px;
  display: inline-flex;
  flex-direction: row;
}

.userMovementsRowEditButton {
  transition: all 0s;
}

.userMovementsRowEditButton__showButton {
  margin-top: 8px;
}

.userMovementsRowEditButton--open {
  transition-delay: .2s;
}

.userMovementsRowEditButton--close {
  @include smallTablet {
    display: none;
  }
}

.dayOfWeek__shadowContainer {
  position: relative;
  z-index: -10;
  @include smallTablet {
    display: none;
  }
}

.dayOfWeek__shadow {
  z-index: -10;
  position: absolute;
  width: 60%;
  left: 20%;
  height: 42px;
  top: 14px;
  box-shadow: 0 6px 20px rgba(1, 1, 1, 0.25);
  @include smallTablet {
    display: none;
  }
}

.dayOfWeek__list {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 26px;
  @include smallTablet {
    margin-left: 35%;
    padding: 24px 14px 0 14px
  }
  @include tablet {
    margin-bottom: 0;
    box-shadow: none;
    background-color: unset;
  }
}

.weeklyMovements__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 8px 0 8px;
  &:first-child::before, &:nth-child(2)::before {
    display: none;
  }
  &::before {
    width: 100%;
    height: 1px;
    background-color: $mildGrey;
    margin: 16px 0;
    content: '';
  }
  &:last-child {
    border: none;
    margin-bottom: 16px;
  }
  @include smallTablet {
    padding: 0;
    &::before {
      display: none;
    }
  }
}

.weeklyMovements__row--user {
  @extend .weeklyMovements__row;
  background-color: $veryLightGrey;
  border-radius: 12px;
  @include smallTablet {
    border-bottom: none;
    display: inline-flex;
    flex-direction: row;
  }
  .weeklyMovements__avatar {
    margin: 12px 0 0 0;
    @include smallTablet {
      margin: 0;
      margin-bottom: 12px;
      padding-left: 12px;
    }
  }
}

.weeklyMovements__row--teamMember {
  @extend .weeklyMovements__row;
  margin-top: 8px;
  @include smallTablet {
    margin: 0;
    margin-bottom: 12px;
    align-items: center;
    flex-direction: row;
  }
}

.weeklyMovements__movements {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  @include smallTablet {
    display: inline-flex;
    width: 68%;
    padding: 0 14px;
  }
}

.weeklyMovements__mobileControls {
  display: flex;
  justify-content: center;
  align-self: center;
  min-width: 75%;
  margin: 18px 0 16px 0;
  @include smallTablet {
    display: none;
  }
  @include tablet {
    margin: 0;
    justify-content: flex-start;
  }
}


.weeklyMovements__mobileControls .button {
  width: 100%;
  @include smallTablet {
    margin-bottom: 12px;
  }
}

.weeklyMovementsViewer__mobileControls .button, .weeklyMovementsViewer__mobileControls .btnFg {
  width: 100%;
  @include smallTablet {
    display: none;
  }
}
