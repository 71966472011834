@import 'src/variables';

.dropDownMenuItem {
  text-align: center;
  color: black;
  padding: 24px 16px;
  text-decoration: none;
  margin: 0 32px;
  cursor: pointer;
  border-bottom: 1px solid $mildGrey;
  justify-content: center;
  display: flex;
  align-items: center;
  @include tablet {
    font-weight: normal;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    border: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(1, 1, 1, .05);
  }
}

.dropDownMenuItem__icon {
  display: none;
  @include tablet {
    display: flex;
    margin-left: 8px;
    color: $darkGrey;
  }
}

.dropDownMenuItem__text {
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.01em;
  padding: 0;
  color: $black;
  @include tablet {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    padding: 8px 16px;
    color: $darkGrey;
  }
}

.dropDownMenuItem--mobileOnly {
  display: flex;
  @include tablet {
    display: none;
  }
}
