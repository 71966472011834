

.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin: 32px 0 0 0;
    max-width: 180px;
    width: 100%;
  }
}