@import 'src/variables';

.userMenu .userAvatarAndName {
  align-self: center;
}

.userMenu__manage {
  margin-right: 8px;
  border-bottom: 4px solid transparent;
  padding: 0 16px;
  @include desktop {
    margin-right: 32px;
  }
}

.userMenu__manage--selected {
  background-color: $blue;
}

.userMenu__closeContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}


.userMenu__item {
  display: none !important;
  user-select: none;
  height: 58px;
  margin-right: 14px;
  @include tablet {
    display: flex !important;
    align-items: center;
    color: $white;
  }
  .userMenu__icon {
    margin-right: 12px;
    margin-top: 4px;
    font-size: 24px;
    color: $white;
  }
  .userMenu__label {
    display: none;
    margin-top: 4px;
    @include desktopSmall {
      display: flex;
    }
  }
}

.userMenu__dropDownButton {
  margin: 0 0 0 16px;
  font-size: 24px !important;
  @include tablet {
    display: none !important;
  }
}

// Manually open with javascript
.userMenu__dropdown--open .dropdownList {
  display: flex;
}
.userMenu__dropdown--open .dropdownList .logoutMenuItem__link {
  justify-content: center;
  img {
    display: none;
  }
}

