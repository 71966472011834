

.addMember__header {
  width: 100%;
}

.addMember__image {
  margin-bottom: 8px;
  width: 100%;
}
