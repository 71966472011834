@import 'src/variables';

.button {
  padding: 4px 16px;
  border-radius: 6px;
  font-family: soleil, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    margin: 0 auto;
  }
  i {
    display: inline-flex;
  }
}

.button--primary {
  box-shadow: 3px 3px 0 0 #180df1;
  background-color: $blue;
  color: white;
  border: 0;
  &:hover {
    background-color: $darkBlue;
  }
}

.button--outline-black {
  border: 1px solid #081e22;
  box-shadow: 2px 2px 0 0 $black;
  background-color: white;
  color: $black;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.button--disabled {
  opacity: 0.5;
  cursor: default;
}

.button--grey {
  @extend .button--outline-black;
}

.button--green {
  background-color: $green;
  box-shadow: 3px 3px 0 0 #24985a;
  color: white;
  border: 0;
  &:hover {
    background-color: #24985a;
  }
}

.button--error {
  background-color: $red;
  box-shadow: 3px 3px 0 0 #c9153c;
  color: white;
  border: 0;
  &:hover {
    background-color: #c9153c;
  }
}

.button--outline-borderless {
  background-color: transparent;
  border: none;
  color: $blue;
}

.button--large {
  min-height: 50px;
  font-size: 16px;
}

.button--medium {
  height: 42px;
  font-size: 14px;
  max-width: 400px;
  width: 100%;
}

.button--small {
  height: 42px;
  font-size: 14px;
}

.button--full-width {
  width: 100%;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  outline-style: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.button--active {
  margin-top: 2px;
  margin-left: 2px;
}

