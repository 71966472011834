@import "src/variables";

.managePage__container {
  min-height: 100vh;
  @include tablet {
    background-color: $veryLightGrey;
  }
}

.managePage {
  max-width: 710px;
  background-color: white;
  border-radius: 12px;
  margin: 16px auto 0 auto;
  @include tablet {
    padding: 48px 96px;
    margin: 32px auto 0 auto;
  }
}

.manage__subtitle {
  margin-right: 8px;
  margin-bottom: 8px;
}

.manage__sections {
  margin-bottom: 24px;
}

.manage__section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: $mildGrey 1px solid;
  .button {
    margin: 16px 0 0 0;
  }
  .iconButton {
    margin: 0 0 0 16px;
  }
}

.teamList__search {
  margin-bottom: 32px;
}

.teamList__header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  a {
    text-decoration: none;
    margin-right: 12px;
    padding: 8px;
  }
}

.teamList__searchHeader {
  margin-bottom: 24px;
}

.teamList__member {
  margin: 26px 0;
  display: block;
  text-decoration: none;
}


.management {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  @include smallTablet {
    min-height: auto;
  }
}

.management__title {
  margin-bottom: 24px;
  align-self: flex-start;
}

.management__back {
  margin-bottom: 24px;
}

.management__list {
  margin: 24px 0;
}

.management__row {
  margin-bottom: 16px;
}

.management__disabled {
  opacity: 0.5;
}

.management__loneControl {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.management__accessRequired {
  border-radius: 12px;
  padding: 24px;
  background: $veryLightGrey;
  margin-bottom: 32px;
  & > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.management__section {
  border-bottom: 1px $mildGrey solid;
  margin-bottom: 24px;
}

.management__complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .management__title {
    align-self: flex-end;
  }
  .row {
    margin-bottom: 16px;
  }
}

.editUse__permissions {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.editUse__permissions--loading {
  background-color: rgba(0,0,0,0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.management__teamOption {
  justify-content: space-between;
  select {
    padding: 4px 32px 4px 4px;
    font-size: 16px;
    border: 1px solid $mildGrey;
    border-radius: 2px;
    color: $black;
  }
  .teamDropDownList {
    margin-bottom: 0;
  }
}

.manage__teamMemberCount {
  margin-top: -8px;
}

.editTeam__membersHeader {
  display: flex;
  margin: 48px 0 16px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $mildGrey;
  .linkButton {
    margin: 0 0 0 auto;
    width: unset;
    max-width: unset;
  }
}

.management__button button {
  min-width: 190px;
}

.management__textBox {
  margin-bottom: 0;
}
