@import '../../variables';

.radioButton__label {
  cursor: pointer;
  font-family: 'proxima-nova';
  font-size: 16px;
  line-height: 24px;
  color: $darkGrey;
  cursor: pointer;
}
.radioButton__input {
  box-shadow: 0px 0px 0px 1px $darkGrey;
  font-size: 3em;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;

  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.radioButton__line {
  display: flex;
  align-content: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.radioButton__input:checked {
  box-shadow: 0px 0px 0px 4px $blue;
  background-color: $blue;
}

.radioButton__label--checked {
  color: $black;
}
