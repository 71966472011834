

// Date Input
.react-date-picker {
  width: 100%;
  margin: 0 0 24px 0;
  & * {
    font-family: "proxima-nova";
  }
}

.react-date-picker .react-date-picker__wrapper {
  padding: 10px 6px;
  border-color: $darkGrey;
  border-radius: 1px;
  flex-direction: row-reverse;
}

.react-date-picker__calendar-button.react-date-picker__button {
  padding: 0 8px;
}
.react-date-picker__calendar-button i {
  font-size: 24px;
  border-color: $darkGrey;
  margin-right: 8px;
}

.react-date-picker .react-date-picker__wrapper input {
  font-size: 18px;
  line-height: 28px;
  color: $darkGrey;
}


// Calendar dropdown
.react-date-picker .react-date-picker__calendar {
  max-width: 100%;
}

.react-date-picker .react-calendar__navigation__label__labelText {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: $black;
}

.react-date-picker .react-calendar__navigation__arrow {
  font-size: 26px;
  border: 1px solid $black;
  border-radius: 50%;
  padding: 4px;
  min-width: unset;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 12px;
}

.react-date-picker .react-calendar__month-view__days__day {
  font-size: 18px;
  color: $black;
  max-width: 48px;
  max-height: 48px;
}

.react-date-picker .react-calendar__month-view__days__day--weekend, .react-date-picker .react-calendar__month-view__days__day--neighboringMonth {
  color: $mildGrey;
}

.react-date-picker .react-calendar__month-view__weekdays {
  text-transform: none;
  abbr {
    text-decoration: none;
    color: $darkGrey;
    font-size: 18px;
  }
  &::first-letter {
    color: red !important;
  }
}

.react-date-picker .react-calendar__tile--now {
  background-color: transparent;
}

.react-date-picker .react-calendar__tile--active {
  background-color: $blue;
  border-radius: 50%;
  color: white;
}
