@import '../../variables';

.tableWrapper {
  height: 90vh;
  width: 100%;
  overflow: auto;
  @include tablet {
    height: unset;
    overflow: unset;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
thead {
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid $black;
}
thead th {
  background-color: $white;
  .table__userCell {
    background-color: $white;
  }
}
thead th:first-child {
  position: sticky;
  z-index: 99;
  left: 0;
}
table tr th:first-child {
  position: sticky;
  inset-inline-start: 0;
}
table tbody th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 90;
}

tbody tr:nth-child(even) {
  background-color: $veryLightGrey;
  .table__userCell {
    background-color: $veryLightGrey;
  }
}
tbody tr:nth-child(odd) {
  background-color: $white;
  .table__userCell {
    background-color: $white;
  }
}
tbody .table__infoCell {
  border-left: 1px solid $mildGrey;
}

.table__userCell {
  position: sticky;
  max-width: 300px;
  z-index: 10;
  text-align: left;
  align-self: center;
}

.table__infoCell {
  min-width: 100px;
  margin: 0 auto;
}
