
.dialog__container {
  z-index: 99998;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  @include smallTablet {
    background-color: $blue10;
  }
}

.dialog__animationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dialog {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 700px;
  position: absolute;
  z-index: 99999;
  background-color: white;
  overflow: hidden;
  @include smallTablet {
    min-width: 200px;
    min-height: 250px;
    padding: 64px 64px 52px 64px;
    box-shadow: 4px 50px 72px $blue50;
    border-radius: 16px;
  }
}

.dialog__content {
  padding: 0 16px 12px 16px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  @include smallTablet {
    padding-top: 0;
  }
}
.dialog__contentContainer {
  overflow-y: auto;
  max-height: 100vh;
  padding-top: 60px;
  @include smallTablet {
    padding: 0;
    max-height: 70vh;
  }
}

.dialog__close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 24px !important;
  padding: 4px;
  transition: background-color .25s;
  border-radius: 2px;
  &:hover {
    background-color: $blue10;
  }
}

.dialog__fields {
  width: 100%;
}

.dialog__spinner {
  margin: 0 auto;
}

.dialog__title img {
  position: absolute;
  right: 0;
}

.dialog__imageAndIconHeader {
  width: 100%;
  align-items: flex-end;
  margin-bottom: 24px;
  min-height: 200px;
  justify-content: flex-start;
  .icon {
    margin-right: 25%;
  }
  @include tablet {
    min-height: unset;
    img {
      align-self: center;
    }
  }
}

.dialog__title {
  margin-bottom: 16px;
}

.addTeamDialog__text {
  margin-bottom: 48px;
}
