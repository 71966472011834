@import 'src/variables';

.editMovements {
  display: none;
  margin-bottom: 16px;
  @include tablet {
    display: block;
    width: 100%;
  }
}

.editMovementsPanel {
  border: 1px $blue solid;
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
}

.editMovementsPanel--desktop {
  display: none;
  @include tablet {
    display: flex;
  }
}

.editMovements__pointer {
  position: absolute;
  transition: transform .5s ease-in-out;
}

.editMovementsPanel__arrow {
  width: 32px;
  height: 32px;
  background-color: white;
  position:relative;
  border-top: 1px solid $blue;
  border-right: 1px solid $blue;
  transform: translateY(0) rotate(-45deg) skew(-5deg, -5deg) translateX(0);
}

.editMovements__close {
  position: absolute;
  top: 16px;
  right: 8px;
}
