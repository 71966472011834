
// base colour for components
$darkGrey: #647478;
$mildGrey: #BCC6C7;
$opaqueMildGrey: RGBA(188, 198, 199, .5);
$lightGrey: #EDEDED;
$veryLightGrey: #FAFAFA;
$white: white;
$black: #081E22;
$darkBlue: #0013BE;
$veryDarkBlue: #1C2684;
$blue: RGBA(0, 87, 255, 1);
$blue10: RGBA(0, 87, 255, .1);
$blue15: RGBA(0, 87, 255, .15);
$blue20: RGBA(0, 87, 255, .2);
$blue50: RGBA(0, 87, 255, .5);
$darkGreen: #1AA85C;
$green: #20CC6F;
$red: #F0224F;
$orange: #f08222;
$yellow: #FFF6A1;
$orange10: RGBA(255, 141, 27, .1);
$orange20: RGBA(255, 141, 27, .2);
$orange50: RGBA(255, 141, 27, .5);

$workingAnywhereButOffice: #BDFAC8;
$workingInOffice: #E3FFAB;
$whereaboutsBusy: #FFE5A1;
$notWorking: #EDEDED;

$mobile: 480px;
$largeMobile: 600px;
$smallTablet: 700px;
$tablet: 767px;
$desktopSmall: 900px;
$desktopMid: 1100px;
$desktop: 1400px;

$radius: 4px;

:root {
  --Neutrals-Light-Grey: #EDEDED;
  --Neutrals-White: #FFF;
}


@mixin fromMobile {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

@mixin largeMobile {
  @media (min-width: #{$largeMobile}) {
    @content;
  }
}

@mixin smallTablet {
  @media (min-width: #{$smallTablet}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktopSmall {
  @media (min-width: #{$desktopSmall}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
