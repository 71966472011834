

.editMyMovements__container {
  transition: max-height .5s;
  overflow: hidden;
  max-height: 0;
  border-radius: 8px;
  display: none;
  @include smallTablet {
    margin-bottom: 12px;
    display: flex;
  }
}

.editMyMovements__container--open {
  max-height: 800px;
}

.editMyMovements__content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.editMyMovements {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: $veryLightGrey;
}

.editMyMovements__syncControl {
  margin-bottom: 12px;
}

.editMyMovements__content {
  width: 100%;
}

.editMyMovements__week {
  width: 60%;
}

.editMyMovements__row {
  background-color: $veryLightGrey;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 8px 24px 8px 24px;
  border-radius: 0;
  transition: background-color .2s, box-shadow .4s;
  min-width: 380px;
  &:last-child {
    border: none;
  }
}

.editMyMovements__row--active {
  background-color: white;
  border-radius: 8px;
  border: none;
  box-shadow: 6px 6px 6px -4px rgba(0,0,0,0.05);
  .editMyMovements__day {
    color: $blue;
  }
}

.editMyMovements__day {
  width: 40%;
  color: $darkGrey;
  min-width: 100px;
}

.editMyMovements__halfPeriod {
  display: flex;
  margin-right: 24px;
}

.editMyMovements__period {
  margin-right: 12px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(0,0,0,0.01);
  }
}

.editMyMovements__period--selected {
  border: 2px solid #0057FF;
}

.editMyMovements__controls {
  width: 100%;
  display: flex;
  justify-content: center;
}