

.textField {
  width: 100%;
  margin-bottom: 24px;
}

.textField--error {
  .textField__input {
    border-color: $red;
    border-width: 2px;
  }
}

.textField__label {
  width: 100%;
  margin-bottom: 8px;
  color: $darkGrey !important;
}

.textField__input {
  width: 100%;
  border: 1px solid RGBA(8, 30, 34, 0.5);
  border-radius: 1px;
  background-color: $white;
  font-family: "proxima-nova";
  color: $black;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding: 8px;
}

.textField__error {
  margin-top: 10px;
  margin-bottom: -4px;
  color: $red;
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
}

.textField__label--error {
  color: $red;
}

.textField--disabled {
  opacity: 0.5;
}

.textField__rightText {
  margin-left: 14px;
}

.textField__leftText {
  margin-right: 14px;
}
