@import './subnav.scss';
@import '../../../variables';

$header-height: 58px;

.header__container {
  //background-color: $veryDarkBlue;
}

.header__container--fullscreen {
  //background-color: $veryDarkBlue;
  position: fixed;
  width: 100vw;
  z-index: 99995;
}

.header {
  background-color: $veryDarkBlue;
  justify-content: space-between;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1440px;
  min-height: $header-height;
}

.header__meetingRooms {
  margin-right: 32px !important;
}

.header__teams {
  justify-content: flex-end;
  margin-right: -16px;
  .logoutMenuItem {
    margin-top: 0;
  }
  .userAvatarAndName {
    display: none;
  }
  .logoutMenuItem__link {
  }
  .logoutMenuItem__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
  }
  @include tablet {
    padding: 0 16px;
    margin-right: 0;
  }
}

.branding {
  height: 100%;

  .logo {
    width: 48px;
    height: 48px;
    background-color: gray;
    border-radius: 4px;
  }

  .brand-name {
    align-self: flex-start;
  }

  a {
    text-decoration: none;
  }
}

.navigation {
  height: 100%;
  justify-self: flex-end;
  align-items: flex-end;
  & > * {
    display: flex;
    align-items: center;
    align-self: center;
  }
  @include tablet {
    align-self: flex-end;
    min-height: $header-height;
  }
}

.navigation__item {
  margin-left: 24px;
  color: white;
  font-weight: 300;
  display: none;

  i {
    margin: 0 10px 0 4px;
  }

  @include tablet {
    display: block;
  }
}

.navigation__item--login, .navigation__item--user {
  display: block;
  align-self: center;
}

.brand {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.company-name {
  color: white;
  margin-top: 2px;
  padding-top: 2px;
  border-top: 1px solid white;
}

.navigation__dropdownDesktop {
  display: none;

  @include tablet {
    display: block;
    position: absolute;
    min-width: 150px;
    right: 0;
    top: 100%;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s;
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, .25);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    &--open {
      max-height: 200px;
    }
  }
}

