@import 'src/variables';

.welcomeDialog__image {
  margin: 36px 0 36px 0;
  width: 100%;
}

.welcomeDialog__title {
  margin-bottom: 16px;
}

.welcomeDialog__description {
  margin-bottom: 48px;
}
