@import 'src/variables';

.emptyTeamView {
  background-color: $veryLightGrey;
  width: 100%;
  padding: 24px 48px;
  justify-content: flex-start;
  flex-direction: column;
  @include tablet {
    padding: 48px 64px 32px 64px;
    flex-direction: row;
  }
}

.emptyTeamView__message {
  align-self: flex-start;
  button {
    margin-top: auto !important;
    margin-bottom: 16px;
  }
  @include tablet {
    height: 160px;
  }
}

.emptyTeamView__title {
  color: $blue;
}

.emptyTeamView__image {
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
    display: none;
  }
  @include tablet {
    height: 160px;
    width: 25%;
    margin-left: 32px;
    img {
      display: block;
    }
  }
}
