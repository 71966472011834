
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.noscroll {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.hidden {
  display: none !important;
}

a .button, a button, a .iconButton {
  text-decoration: none;
  text-transform: none;
}

.content {
  width: 100%;
  padding: 0 16px 16px 16px;
  margin: 0 auto;
  @include desktop {
    max-width: $desktop;
    justify-content: space-between;
    padding: 0 16px;
  }
}

.content--fullscreen {
  width: 100vw;
  max-width: 2000px;
  height: calc(100vh - 60px) !important;
  max-height: calc(100vh - 60px) !important;
  margin-top: 60px !important;
  padding: 0 !important;
  overflow: hidden;
  @include desktop {
    justify-content: space-between;
    padding: 0 0 0 0 !important;
  }
}

.content--fullscreen--ribbon {
  width: 100vw;
  max-width: 2000px;
  height: calc(100vh - 114px) !important;
  max-height: calc(100vh - 114px) !important;
  margin-top: 114px !important;
  padding: 0 !important;
  overflow: hidden;
  @include desktop {
    justify-content: space-between;
    padding: 0 0 0 0 !important;
  }
}

.content--fullscreen--teams {
  width: 100vw;
  max-width: 2000px;
  height: 100vh !important;
  max-height: 100vh !important;
  margin-top: 0;
  padding: 0 !important;
  @include desktop {
    justify-content: space-between;
    padding: 0 0 0 0 !important;
  }
}

.content--fullscreen--teams--ribbon {
  width: 100vw;
  max-width: 2000px;
  height: calc(100vh - 64px) !important;
  max-height: calc(100vh - 64px) !important;
  margin-top: 0;
  padding: 0 !important;
  @include desktop {
    justify-content: space-between;
    padding: 0 0 0 0 !important;
  }
}

.section {
  border-bottom: $mildGrey 1px solid;
  padding-bottom: 24px;
  margin-bottom: 24px;
  &--borderless {
    border: none;
  }
}


.field {
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 1px;
  border: 0.5px solid $black;
}

.container {
  margin: 0 auto;
  width: $desktop - 32px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.column--top {
  @extend .column;
  align-self: flex-start;
}
.column--center {
  @extend .column;
  align-self: center;
}
.column--bottom {
  @extend .column;
  align-self: flex-end;
}

.debug {
  border: 1px solid red !important;
  & * {
    border: 1px solid rgba(255, 0, 0, 0.4) !important;
  }
}

.noDecoration {
  text-decoration: none;
}

.hide--tablet {
  @include tablet {
    display: none !important;
  }
}

i {
  user-select: none;
}
