@import 'src/variables';

.fullScreenCalendar__container {
  width: 100vw;
  height: 0;
  position: fixed;
  top: -10vh;
  left: 0;
  background-color: white;
  z-index: 1000;
  padding: 0 24px;
  overflow: hidden;
  transition: height .4s ease-in-out;
  box-shadow: 0 -2px 8px black;
}

.fullScreenCalendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 120px;
  button {
    margin-top: 24px;
  }
  @include tablet {
    padding-top: 0;
  }
}

.fullScreenCalendar__close {
  position: absolute;
  top: 8px;
  right: 0;
}

.fullScreenCalendar__header {
  width: 100%;
  margin-bottom: 38px;
}

.fullScreenCalendar__container--open {
  height: 110vh;
}
