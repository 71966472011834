
$width: 58px;
$height: 48px;

$focused-width: 90px;
$focused-height: 72px;

.movement__icon {
  height: 100%;
  width: 90%;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 40px;
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  img {
    max-width: 80%;
    transition: height 0.5s linear(
                    0, 0.004, 0.016, 0.035, 0.063, 0.098, 0.141 13.6%, 0.25, 0.391, 0.563, 0.765,
                    1, 0.891 40.9%, 0.848, 0.813, 0.785, 0.766, 0.754, 0.75, 0.754, 0.766, 0.785,
                    0.813, 0.848, 0.891 68.2%, 1 72.7%, 0.973, 0.953, 0.941, 0.938, 0.941, 0.953,
                    0.973, 1, 0.988, 0.984, 0.988, 1
    ), width 0.5s linear(
                    0, 0.004, 0.016, 0.035, 0.063, 0.098, 0.141 13.6%, 0.25, 0.391, 0.563, 0.765,
                    1, 0.891 40.9%, 0.848, 0.813, 0.785, 0.766, 0.754, 0.75, 0.754, 0.766, 0.785,
                    0.813, 0.848, 0.891 68.2%, 1 72.7%, 0.973, 0.953, 0.941, 0.938, 0.941, 0.953,
                    0.973, 1, 0.988, 0.984, 0.988, 1
    );
    height: 48px;
    width: 48px;
  }
}

.movements__icon--loading {
  img {
    height: 24px !important;
    width: 24px !important;
  }
}

.movement__icon--not_working img {
  min-width: 24px !important;
  min-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

.movements__icon--focused {
  box-shadow: 0 0 4px 1px RGBA(0, 87, 255, 0.5);
}


.movement__icon--unknown {
  background: white !important;
  border: 0.5px solid $darkGrey;
}

.movement__icon--disabled {
  background: #EDEDED !important;
  img {
    filter: grayscale(1);
  }
}

.movement__icon--green {
  background: $workingAnywhereButOffice;
}

.movement__icon--lightgreen {
  background: $workingInOffice;
}

.movement__icon--grey {
  background: $notWorking;
}

.movement__icon--red {
  background: $red;
}

.movement__icon--yellow {
  background: $whereaboutsBusy;
}

.movement__iconHalfDay {
  position: relative;
  .movement__icon--am, .movement__icon--pm {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      padding: 0;
    }
  }
  .movement__icon--am {
    width: 50%;
    border-right: 0.5px solid white;
    img {
      max-width: 90%;
    }
  }
  .movement__icon--pm {
    width: 50%;
    border-left: 0.5px solid white;
    img {
      max-width: 90%;
    }
  }
}
