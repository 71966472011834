@import 'src/variables';

.editMovementsAnimation--hidden {
  @extend .editMovementsAnimation;
  opacity: 0 !important;
  max-height: 0 !important;
  padding: 0 !important;
}

.editMovementsAnimation {
  opacity: 1;
  overflow: hidden;
  max-height: 500px;
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
}
