@import '../../../variables';

.daysOfWeekList__row {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px $mildGrey solid;
  cursor: pointer;
  min-height: 92px;
  &:last-child {
    border: none;
  }
}

.daysOfWeekList__row--active {
  background-color: $white
}

.completeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://app.team-today.com/assets/icons/font/blue-tick.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;
  margin: 0 16px 0 16px;
  transition: all .25s ease-in-out;
  width: 24px;
  height: 24px;
}

.completeIcon--visible {
  width: 24px;
  height: 24px;
  padding-right: 12px;
  background-size: 100%;
}

.daysOfWeekList__name {
  flex: 1 1 0;
}

.daysOfWeekList__movement, .daysOfWeekList .movement__icon {
  flex: 1 1 0;
  max-width: 92px;
  height: 60px !important;
}
