@import 'src/variables';

.wallchartHeader__daysOfWeekContainer {
  display: flex;
  box-shadow: 0 10px 15px -20px #000000;
  flex-direction: column;
  margin-bottom: 16px;
  z-index: 999;
  width: 100%;
  @include smallTablet {
    min-width: 640px;
    flex-direction: row;
    margin-bottom: 0;
    padding: 0 16px;
    .weekFlicker {
      padding-bottom: 0;
    }
  }
}

.wallchartHeader__date {
  font-size: 14px;
  line-height: 18px;
}

.wallchartHeader__day {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  //padding: 8px;
}

.wallchartHeader__day--active {
  box-shadow: 0 0 16px 0 $blue15;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 74px;
  display: flex;
}

.wallchartHeader__daysOfWeekContainer--sticky {
  @media (max-width: #{$smallTablet}) {
    position: fixed;
    top: 0;
    box-shadow: 0 -2px 8px black;
    width: 100vw;
    z-index: 10001;
    margin: 0 -16px;
    .wallchartHeader__daysOfWeek {
      margin: 0 0 0 -8px !important;
      padding-right: 16px !important;
      padding-left: 24px !important;
    }
    .weekFlicker {
      margin: 0 0 0 -8px !important;
      padding-right: 8px !important;
      padding-left: 24px !important;
      border-radius: 0;
    }
  }
}

.wallchartHeader__daysOfWeekContainer--sticky ~ div {
  @media (max-width: #{$smallTablet}) {
    margin-top: 130px;
  }
}
.wallchartHeader__daysOfWeekContainer--sticky ~ .wallchart__teams {
  @media (max-width: #{$smallTablet}) {
    padding-top: 130px;
  }
}

.wallchartHeader__weekFlicker {
  width: 100%;
  @include smallTablet {
    width: 32%;
  }
}

.wallchartHeader__daysOfWeek {
  display: flex;
  flex: 1;
  background-color: white;
  justify-content: space-between;
  @include smallTablet {
    margin: 0 0 0 auto;
    width: 68%;
  }

  .wallchartHeader__day {
    min-height: 42px;
    padding: 8px 0 8px 0;
    p {
      line-height: 20px;
      font-family: "soleil", sans-serif;
    }
    p:first-child {
      margin-bottom: 4px;
      margin-top: 10px;
    }
    @include smallTablet {
      padding: 0 0 8px 0;
      p {
        line-height: 18px;
      }
    }
  }
}

