@import 'src/variables';

.deleteTeamDialog {
  display: flex;
  flex-direction: column;
  max-width: 524px;
}

.deleteTeamDialog__title {
  margin-bottom: 24px;
}

.deleteTeamDialog__content {
  border-radius: 12px;
  background-color: $veryLightGrey;
  padding: 32px 24px;
  margin-bottom: 32px;
}

.deleteTeamDialog__message {
  margin-bottom: 16px;
}

.deleteTeamDialog__info {
  margin-bottom: 48px;
}

.deleteTeamDialog__users {
  margin: 8px 0;
}

.deleteTeamDialog__button {
  margin-bottom: 16px;
}
