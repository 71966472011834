@import '../../variables';

.weekFlicker {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 60px;
  margin-bottom: 0;
  user-select: none;
  width: 100%;
  @include desktopSmall {
    max-width: 400px;
    margin-bottom: 0;
    .weekFlicker__calendar {
      display: none;
    }
  }
}

.weekFlicker__icon--tablet {
  display: none !important;
  @include desktopSmall {
    display: flex !important;
  }
}

.weekFlicker__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  @include smallTablet {
    padding: 8px 16px;
  }
}

.weekFlicker__dateLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  i {
    margin: 0 0 0 12px;
    font-size: 24px;
    line-height: 24px;
  }
}

.circle--disabled {
  color: $lightGrey;
  border-color: $lightGrey;
  filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(1661%) hue-rotate(153deg) brightness(85%) contrast(80%);
}

.weekFlicker .icon {
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.weekFlicker__control--mobile {
  display: flex !important;
  @include desktopSmall {
    display: none !important;
  }
}

.weekFlicker__control--tablet {
  display: none !important;
  @include desktopSmall {
    display: flex !important;
  }
}

.weekFlicker__dateLabel--mobile {
  @include desktopSmall {
    display: none;
  }
}

.weekFlicker__dateLabel--desktop {
  display: none;
  @include desktopSmall {
    display: flex;
  }
}
