
.deleteUserDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteUserDialog__button {
  margin-bottom: 16px;
}

.deleteUserDialog__user {
  margin: 24px 0;
}
