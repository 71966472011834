@import '../../variables';


.stripe label {
  color: #6C7D82;
  font-weight: 400;
  font-size: 16px;
  font-family: sans-serif;
}

.stripe .textField {
  margin-bottom: 0;
}

.stripe button:hover {
}

.stripe input, .stripe .StripeElement {
  background: #FFFFFF;
  border: 1px solid $darkGrey;
  box-sizing: border-box;
  border-radius: 1px;
  margin: 8px 0 24px 0;
  line-height: 28px;
  font-size: 18px;
  padding: 8px 16px;
  font-family: sans-serif;
  outline: 0;
}

.stripe input::placeholder {
  color: #aab7c4;
  font-family: sans-serif;
}

.stripe input:focus,
.stripe .StripeElement--focus {
}

.stripe .StripeElement.IdealBankElement,
.stripe .StripeElement.FpxBankElement,
.stripe .StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe .StripeElement.PaymentRequestButton {
}
