
.dashboard {
  display: flex;
  flex-direction: column;
  @include desktop {
    justify-content: space-between;
    flex-direction: row;
  }
}

.teamMovements {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 16px 0;
  @include desktop {
    margin-right: 32px;
  }
}

.teamMovements__header {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  @include tablet {
    transition: opactity .5s;
    color: $black;
    flex-direction: row;
    margin-bottom: 6px;
  }
}

.teamMovements__addTeamMemberButton {
  max-width: 400px;
  align-self: center;
  margin: 32px 0 0 0;
}

.teamMovements__calendar {
  display: none;
  @include desktop {
    display: flex;
  }
}

.teamMovements__headerLeft {
  transition: opactity .5s;
  color: $black;
  flex-direction: row;
  margin-bottom: 6px;
  width: 100%;
  @include tablet {
    max-width: 600px;
  }
}

.teamMovements__headerRight {
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-bottom: 20px;
  display: none;
  @include tablet {
    display: flex;
  }
}

.teamMovements__teams {
  transition: opacity .5s;
  color: $green;
}

.teamMovements__teams--editting {
  @include smallTablet {
    opacity: 0.15;
  }
}

.teamMovements__noTeam {
  background-color: $veryLightGrey;
  width: 100%;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    width: 90%;
    padding: 16px 16px 32px 16px;
  }
}

.teamMovements__noTeamContent {
  max-width: 564px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  @include tablet {
    padding: 16px;
  }
}

.teamMovements__noTeamContent img {
  width: 100%;
  max-width: 340px;
  margin: 32px auto 24px auto;
  align-self: center;
  justify-self: center;
}
.teamMovements__noTeamText {
  @include tablet {
    max-width: 400px;
  }
}
