@import 'src/variables';

.deleteCompanyDialog {
  display: flex;
  flex-direction: column;
  max-width: 524px;
}

.deleteCompanyDialog__title {
  margin-bottom: 24px;
}

.deleteCompanyDialog__content {
  border-radius: 12px;
  background-color: $veryLightGrey;
  padding: 32px 24px;
  margin-bottom: 48px;
}

.deleteCompanyDialog__message {
  margin-bottom: 16px;
}

.deleteCompanyDialog__info {
  margin-bottom: 24px;
}

.deleteCompanyDialog__users {
  margin: 8px 0;
}

.deleteCompanyDialog__button {
  margin-bottom: 16px;
}
