
.icon__tick--big {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $blue;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    color: $white;
  }
}

.icon--success {
  background-image: url('https://app.team-today.com/assets/icons/font/tick.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}
