@import 'src/variables';

.editMovementsMobile {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99990;
  height: 100vh;
  overflow: auto;
  @include tablet {
    display: none !important;
  }
}

.editMovementsMobile .movement__icon {
  max-width: 74px;
  max-height: 56px;
  min-width: 74px;
  min-height: 56px;
  overflow: hidden;
}

.editMovementsMobile__header {
  justify-content: space-between;
  padding: 8px 8px 8px 0;
}

.editMovementsMobile__saved {
  background-color: $veryLightGrey;
  border-radius: 12px;
  margin: 0 16px;
  padding: 16px;
  .icon {
    margin-right: 16px;
  }
}

.editMovementsMobile__weekFlicker {
  max-width: 300px;
  margin-bottom: 0;
}

.editMovementsMobile__close {
  .icon {
    margin-right: 0;
  }
}

.editMovementsMobile__controls {
  padding: 0 16px;
  margin: 24px 0 36px 0;
}
