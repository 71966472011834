@import 'src/variables';

.permissionNeeded {
  display: flex;
  flex-direction: column;
}

.permissionNeeded__title {
  margin-bottom: 24px;
}

.permissionNeeded__content {
  border-radius: 12px;
  background-color: $veryLightGrey;
  padding: 32px 24px;
  margin-bottom: 48px;
}

.permissionNeeded__message {
  margin-bottom: 16px;
}

.permissionNeeded__info {
  line-height: 28px;
}

.permissionNeeded__users {
  margin: 8px 0;
}
