@import '../variables.scss';

@import './theme/base';
@import './theme/fonts';
@import './theme/content-card';

@import './components/header/header.scss';
@import './components/week-flicker';
@import './components/calendar';
@import './components/movement';
@import './components/user-avatar-and-name';
@import './components/week-commencing-date';
@import './components/weekly-movements-setter/days-of-week-list.scss';
@import './components/weekly-movements-setter/activity-list.scss';
@import './components/text-field';
@import './components/switch';
@import './components/icons';
@import './components/link-button';
@import './components/selectable-content-card';
@import './components/checkbox';
@import './components/react-date-picker';
@import './components/stripe';
@import './components/scroll-table';
@import './components/dropdown';
@import './components/grid';
@import './components/radio-button';

@import './views/edit-movements/edit-user-movements';
@import './views/edit-movements/mobile-edit-movements';
@import './views/edit-movements/mobile-edit-movements-content';
@import './views/edit-movements/time-frame-selector';
@import './views/movement-icons';
@import './views/weekly-team-movements-viewer';
@import './views/team-view';
@import './views/auth/login';
@import './views/auth/forgot-password';
@import './views/company-movements/company-movements-page';
@import './views/company-movements/add-team-dialog';

@import './dialogs/dialog';
@import './dialogs/add-member-dialog';

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  min-width: 350px;
  overflow-y: auto;
}

html {
  overflow-x: hidden;
}
