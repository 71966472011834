@import '../../../variables';

.mobileEditMovements {
  width: 100%;
}

.mobileEditMovements__row {
  .movement__icon {
    transition: border .25s;
  }
  &:last-child {
    .mobileEditMovements__dayHeader {
      border-bottom: none;
    }
  }
}

.mobileEditMovements__row--active {
  .mobileEditMovements__content {
    max-height: 800px;
    padding: 16px 16px 16px 16px;
  }
  .movement__icon {
    border: 2px solid $blue;
  }
}

.mobileEditMovements__content {
  max-height: 0;
  transition: max-height .3s, padding .3s;
  overflow: hidden;
  background-color: $veryLightGrey;
}

.mobileEditMovements__dayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  margin: 0 16px;
  border-bottom: 1px $mildGrey solid;
}

.mobileEditMovements--highlighted {
  color: $blue;
}

.mobileEditMovements__movementIcon, .mobileEditMovements__dayHeader .movement__iconHalfDay {
  max-width: 74px;
  max-height: 56px;
  min-width: 74px;
  min-height: 56px;
  .movement__icon {
    height: 56px;
  }
}
